import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import loadingAnimation from 'src/assets/animations/LoadingAnimation.json';

import { LottieAnimation } from 'src/components/animate';

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const Page501 = lazy(() => import('src/pages/501'));

// ----------------------------------------------------------------------

export const errorRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense
          fallback={
            <LottieAnimation
              animationData={loadingAnimation}
              bgColor="#043F6B"
              height={180}
              width={180}
            />
          }
        >
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: '501', element: <Page501 /> },
    ],
  },
];
