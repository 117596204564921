/* eslint-disable no-plusplus */
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  useSensor,
  DndContext,
  useSensors,
  DragEndEvent,
  closestCenter,
  PointerSensor,
} from '@dnd-kit/core';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import uuidv4 from 'src/utils/uuidv4';
import { isQuestionOrSection } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';
import { saveTestQuestions } from 'src/api/staff/tests';

import Iconify from 'src/components/iconify/iconify';
import { enqueueSnackbar } from 'src/components/snackbar';
import QuestionForm from 'src/components/question/question-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

import {
  ITestSection,
  ITestQuestion,
  saveTestYupSchema,
  SaveTestSectionsYupSchema,
  SaveTestQuestionsYupSchema,
} from 'src/types/staff/tests';

import TestSectionBox from './test-section-box';
import TestQuestionsBox from './test-question-box';
import NoQuestionsAdded from './test-no-questions';
import QuestionSearchBox from './test-questions-search-box';
import TestSectionsDropdown from './test-sections-dropdown';

function TestEditQuestionsView({
  testId,
  testQuestions,
  sections,
  isRandomized,
  dimensions,
  totalQuestions,
}: {
  testId: string;
  testQuestions: ITestQuestion[];
  sections: ITestSection[];
  isRandomized: boolean;
  dimensions: number | null;
  totalQuestions: number;
}) {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const queryClient = useQueryClient();
  const sensors = useSensors(useSensor(PointerSensor));
  const { t } = useTranslate();

  const newSectionDialog = useBoolean(false);
  const [newSectionName, setNewSectionName] = useState('');
  const [newSectionInstruction, setNewSectionInstruction] = useState('');

  const [editQuestionId, setEditQuestionId] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const [selectedQuestions, setSelectedQuestions] = useState<ITestQuestion[]>([]);
  const [questionTargetLocationId, setQuestionTargetLocationId] = useState('');
  const [moveToNewSectionId, setMoveToNewSectionId] = useState<string | null>(null);

  const defaultValues: SaveTestQuestionsYupSchema = useMemo(() => {
    const sortedSections = sections.map((section) => ({
      ...section,
      testId,
      questions: testQuestions
        .filter((question) => question.sectionId === section.id)
        .sort((a, b) => a.position - b.position),
    }));

    const independentQuestions = testQuestions.filter((question) => !question.sectionId);

    return {
      independentQuestions,
      sections: sortedSections,
      isRandomized,
      dimension: dimensions,
      totalQuestions,
    };
  }, [dimensions, isRandomized, sections, testId, testQuestions, totalQuestions]);

  const [dndItems, setDndItems] = useState(
    [...defaultValues.independentQuestions, ...defaultValues.sections!].sort(
      (a, b) => a.position - b.position,
    ),
  );

  const { itemSections } = useMemo(() => {
    const independentQuestionsArr = dndItems.filter(
      (item) => isQuestionOrSection(item) === 'question',
    );
    const sectionsArr = dndItems.filter((item) => isQuestionOrSection(item) === 'section');
    return {
      itemIndependentQuestions: independentQuestionsArr,
      itemSections: sectionsArr,
    };
  }, [dndItems]);

  const handleNewSectionDialogOpen = () => {
    newSectionDialog.onTrue();
  };

  const handleDialogClose = () => {
    newSectionDialog.onFalse();
  };

  const methods = useForm({
    resolver: yupResolver(saveTestYupSchema),
    defaultValues,
  });

  const itemsWithQuestionNumbers = useMemo(() => {
    let questionNumber = 1;
    return dndItems.map((item) => {
      if (isQuestionOrSection(item) === 'question') {
        return { ...item, questionNumber: questionNumber++ };
      }
      if (isQuestionOrSection(item) === 'section') {
        const sectionWithNumberedQuestions = {
          ...item,
          questions: (item as SaveTestSectionsYupSchema).questions.map((q) => ({
            ...q,
            questionNumber: questionNumber++,
          })),
        };
        return sectionWithNumberedQuestions;
      }
      return item;
    });
  }, [dndItems]);

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    watch,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleCreateSection = () => {
    if (!newSectionName.trim()) {
      enqueueSnackbar(t('test_edit_page.questions.section_name_not_empty'), { variant: 'error' });
      return;
    }
    const newSection = {
      name: newSectionName,
      questions: [],
      position: independentQuestions.length + sections.length + 1,
      instruction: newSectionInstruction,
      testId,
      _tempId: uuidv4(),
    };

    const newSectionItem = {
      ...newSection,
      id: newSection._tempId,
    };

    const existingSections = methods.getValues('sections') || [];
    methods.setValue('sections', [...existingSections, newSection], {
      shouldDirty: true,
    });
    setDndItems([...dndItems, newSectionItem]);
    setNewSectionName('');
    setNewSectionInstruction('');
    handleDialogClose();

    if (selectedQuestions.length > 0) setMoveToNewSectionId(newSectionItem.id);
  };

  const onSubmit = handleSubmit(async (data) => {
    const totalQuestionsCount =
      data.independentQuestions.length +
      (data.sections || []).reduce((total, section) => total + section.questions.length, 0);

    if (data.isRandomized && data.dimension && totalQuestionsCount < data.dimension) {
      enqueueSnackbar(
        t('test_edit_page.questions.dimensions_not_satisfied', {
          required: data.dimension,
          missing: data.dimension - totalQuestionsCount,
        }),
        { variant: 'error' },
      );
      return false;
    }

    try {
      await saveTestQuestions(testId, data);
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTest, testId] });
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      return enqueueSnackbar(t('test_edit_page.questions.saved_questions'), { variant: 'success' });
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const handleCancel = useCallback(() => {
    // Reset the form to its default values
    reset(defaultValues);

    // Reset dndItems to its initial state
    setDndItems(
      [...defaultValues.independentQuestions, ...defaultValues.sections!].sort(
        (a, b) => a.position - b.position,
      ),
    );

    // Reset other state variables
    setSelectedQuestions([]);
    setEditQuestionId('');
    setIsEditing(false);
    setQuestionTargetLocationId('');
    setMoveToNewSectionId(null);
  }, [defaultValues, reset]);

  const handleSearchQuestionChoice = (question: ITestQuestion) => {
    const { id, ...questionInfo } = question;

    const formattedQuestion = {
      ...questionInfo,
      _tempId: uuidv4(),
      questionId: id,
      testId,
    };

    const formattedItem = {
      ...formattedQuestion,
      id: formattedQuestion._tempId,
    };

    const updatedFormQuestions = [...independentQuestions, formattedQuestion].map((q, index) => ({
      ...q,
      position: index + 1,
    }));

    const updatedDndItems = [...dndItems, formattedItem].map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    methods.setValue('independentQuestions', updatedFormQuestions, {
      shouldDirty: true,
    });
    setDndItems(updatedDndItems);
  };

  const independentQuestions = watch('independentQuestions');
  const sectionsArr = watch('sections');
  const formDimensions = watch('dimension');
  const isRandomizedWatch = watch('isRandomized');

  const updateTotalQuestions = useCallback(() => {
    const independentQuestionsCount = independentQuestions.length;
    const sectionQuestionsCount = sectionsArr!.reduce(
      (total, section) => total + (section.questions ? section.questions.length : 0),
      0,
    );

    const sumOfQuestions = independentQuestionsCount + sectionQuestionsCount;

    methods.setValue('totalQuestions', sumOfQuestions, {
      shouldDirty: true,
    });
  }, [independentQuestions, sectionsArr, methods]);

  useEffect(() => {
    updateTotalQuestions();
  }, [updateTotalQuestions]);

  const excludeIdsFromSearch = useMemo(() => {
    const independentQuestionIds = independentQuestions.map((question) => question.questionId!);
    const sectionQuestionIds = (sectionsArr || []).flatMap((section) =>
      (section.questions || []).map((question) => question.questionId!),
    );
    return [...independentQuestionIds, ...sectionQuestionIds].filter((id) => id);
  }, [independentQuestions, sectionsArr]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId !== overId) {
      const activeItemIndex = dndItems.findIndex((item) => item.id === activeId);
      const overItemIndex = dndItems.findIndex((item) => item.id === overId);

      const reorderedSectionsAndQuestions = arrayMove(dndItems, activeItemIndex, overItemIndex);
      const updatedSectionsAndQuestions = reorderedSectionsAndQuestions.map(
        (sectionOrQuestion, index) => ({
          ...sectionOrQuestion,
          position: index + 1,
        }),
      );

      setDndItems(updatedSectionsAndQuestions);

      methods.setValue(
        'independentQuestions',
        updatedSectionsAndQuestions
          .filter((currentItem) => isQuestionOrSection(currentItem) === 'question')
          .map((q) => {
            if (q._tempId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = q;
              return rest;
            }
            return q;
          }) as any,
        {
          shouldDirty: true,
        },
      );

      methods.setValue(
        'sections',
        updatedSectionsAndQuestions
          .filter((currentItem) => isQuestionOrSection(currentItem) === 'section')
          .map((s) => {
            if (s._tempId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = s;
              return rest;
            }
            return s;
          }) as any,
        {
          shouldDirty: true,
        },
      );
    }
  };

  const [isNewQuestion, setIsNewQuestion] = useState(false);

  const handleAddNewQuestion = () => {
    if (isEditing) return;
    const newQuestion = {
      _tempId: uuidv4(),
      question: t('common.new_question'),
      answers: [
        {
          text: t('test_edit_page.questions.option_1'),
          points: 5,
        },
        {
          text: t('test_edit_page.questions.option_2'),
          points: 0,
        },
      ],
      totalPoints: 5,
      questionId: '',
      instruction: t('common.instructions'),
      position: 0,
      sectionId: null,
      testId,
      tags: [],
      isPublished: true,
      questionType: 'single' as 'single' | 'multiple' | 'text',
      isUpdatedOnQuestionsTable: false,
      isNewQuestion: true,
    };

    const newDndItem = {
      ...newQuestion,
      id: newQuestion._tempId,
    };

    const updatedFormQuestions = [...independentQuestions, newQuestion].map((question, index) => ({
      ...question,
      position: index + 1,
    }));
    const updatedDndItems = [...dndItems, newDndItem].map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    methods.setValue('independentQuestions', updatedFormQuestions, {
      shouldDirty: true,
    });
    setDndItems(updatedDndItems);
    setEditQuestionId(newQuestion._tempId);
    setIsNewQuestion(true);
    setIsEditing(true);
  };

  const handleEditQuestion = (questionId: string) => {
    setEditQuestionId(questionId);
    setIsNewQuestion(false);
    setIsEditing(true);
  };

  const handleCloseEditQuestion = ({ isEditCancelled = false }: { isEditCancelled?: boolean }) => {
    if (editQuestionId && isEditCancelled) {
      // Check if the question is in independentQuestions
      const independentIndex = independentQuestions.findIndex(
        (q) => 'isNewQuestion' in q && q._tempId === editQuestionId,
      );
      if (independentIndex !== -1) {
        // Remove from independentQuestions
        const updatedIndependents = [
          ...independentQuestions.slice(0, independentIndex),
          ...independentQuestions.slice(independentIndex + 1),
        ];
        methods.setValue('independentQuestions', updatedIndependents, {
          shouldDirty: true,
        });

        setDndItems((prevItems: any) => {
          const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
          return prevItemsCopy.filter(
            (item: { _tempId: string }) => item?._tempId !== editQuestionId,
          );
        });
      }
    }
    setEditQuestionId('');
    setIsEditing(false);
  };

  const handleQuestionSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    question: ITestQuestion,
  ) => {
    const questionId = question.id || question._tempId;
    if (event.target.checked) {
      setSelectedQuestions((curSelectedQuestions) => [...curSelectedQuestions, question]);
    } else {
      setSelectedQuestions((curSelectedQuestions) =>
        curSelectedQuestions.filter((q) => (q.id || q._tempId) !== questionId),
      );
    }
  };

  const handleMoveQuestions = useCallback(
    (targetSectionId: string | null, singleQuestionToMove?: ITestQuestion) => {
      if (selectedQuestions.length === 0 && !singleQuestionToMove) return;

      let newItems;
      let itemsCopy = JSON.parse(JSON.stringify(dndItems));
      let questionsToBeMoved = [];
      const targetLocation = targetSectionId;

      if (singleQuestionToMove) {
        questionsToBeMoved.push(singleQuestionToMove);
      } else {
        // Filter out questions that aren't in the target location already
        questionsToBeMoved = selectedQuestions.filter(
          (question) => question.sectionId !== targetLocation,
        );
      }

      // Remove questions from their original locations
      questionsToBeMoved.forEach((questionToMove) => {
        if (questionToMove.sectionId) {
          const originalSectionIdx = itemsCopy.findIndex(
            (item: any) =>
              item.id === questionToMove.sectionId || item._itemId === questionToMove.sectionId,
          );
          if (originalSectionIdx !== -1) {
            const originalQuestionIdx = itemsCopy[originalSectionIdx].questions.findIndex(
              (q: any) => q.id === questionToMove.id,
            );
            if (originalQuestionIdx !== -1)
              itemsCopy[originalSectionIdx].questions.splice(originalQuestionIdx, 1);
            itemsCopy[originalSectionIdx].questions = itemsCopy[originalSectionIdx].questions.map(
              (item: any, idx: number) => ({
                ...item,
                position: idx + 1,
              }),
            );
          }
        } else {
          const originalQuestionIdx = itemsCopy.findIndex(
            (item: any) => item.id === questionToMove.id,
          );
          itemsCopy.splice(originalQuestionIdx, 1);
          itemsCopy = itemsCopy.map((item: any, idx: number) => ({
            ...item,
            position: idx + 1,
          }));
        }
      });

      // If target ID is present it means a target section was chosen
      if (targetSectionId) {
        // Find the index of the target section in the items copy
        const targetSectionIdx = itemsCopy.findIndex((item: any) => item.id === targetSectionId);

        if (targetSectionIdx !== -1) {
          const targetSectionCurQuestions = itemsCopy[targetSectionIdx].questions;
          const joinedQuestions = [...questionsToBeMoved, ...targetSectionCurQuestions];
          const updatedQuestions = joinedQuestions.map((q, idx) => ({
            ...q,
            sectionId: itemsCopy[targetSectionIdx].id,
            position: idx + 1,
          }));

          itemsCopy[targetSectionIdx].questions = updatedQuestions;
          newItems = itemsCopy;
        }
        // Else no target section was chosen, so the questions need to be moved to be independent
      } else {
        const joinedItems = [...questionsToBeMoved, ...itemsCopy];
        newItems = joinedItems.map((item, idx) => ({
          ...item,
          sectionId: null,
          position: idx + 1,
        }));
      }

      setDndItems(newItems);
      setSelectedQuestions([]);

      methods.setValue(
        'independentQuestions',
        newItems
          .filter(
            (currentItem: Record<string, any>) => isQuestionOrSection(currentItem) === 'question',
          )
          .map((q: { [x: string]: any; _tempId?: any; id?: any }) => {
            if (q._tempId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = q;
              return rest;
            }
            return q;
          }) as any,
        {
          shouldDirty: true,
        },
      );

      methods.setValue(
        'sections',
        newItems
          .filter(
            (currentItem: Record<string, any>) => isQuestionOrSection(currentItem) === 'section',
          )
          .map((s: { [x: string]: any; _tempId?: any; id?: any }) => {
            if (s._tempId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = s;
              return rest;
            }
            return s;
          }) as any,
        {
          shouldDirty: true,
        },
      );
    },
    [dndItems, methods, selectedQuestions],
  );

  useEffect(() => {
    if (moveToNewSectionId) {
      handleMoveQuestions(moveToNewSectionId);
      setMoveToNewSectionId(null);
    }
  }, [handleMoveQuestions, moveToNewSectionId]);

  const renderAddQuestionToolbar = (
    <Stack direction="row" alignItems="center" justifyContent="center" py={3}>
      <Button
        variant="contained"
        startIcon={<Iconify icon="mingcute:add-line" />}
        sx={{ minWidth: '142px', backgroundColor: 'secondary.main' }}
        onClick={handleAddNewQuestion}
        disabled={isEditing || selectedQuestions.length > 0}
      >
        {t('common.new_question')}
      </Button>
      <Typography
        variant="body2"
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '24px',
          color: 'grey.500',
          minWidth: '70px',
          textAlign: 'center',
        }}
      >
        {t('common.or')}
      </Typography>
      <Box sx={{ minWidth: '425px' }}>
        <QuestionSearchBox
          excludeIds={excludeIdsFromSearch}
          onQuestionChosen={handleSearchQuestionChoice}
          disabled={isEditing || selectedQuestions.length > 0}
        />
      </Box>
    </Stack>
  );

  return (
    <>
      <Stack sx={{ mt: 5 }} gap={5}>
        {selectedQuestions.length > 0 && (
          <Stack
            direction="row"
            alignItems="center"
            spacing="24px"
            sx={{
              position: 'sticky',
              top: 235,
              zIndex: 999,
              backgroundColor: 'background.default',
              py: 2,
            }}
          >
            <Stack direction="row" alignItems="center" spacing="8px">
              <Iconify icon="ri:check-double-fill" color="grey.600" />
              <Typography
                variant="body2"
                sx={{ fontSize: '14px', fontWeight: '900', color: 'grey.600' }}
              >
                {selectedQuestions.length} {t('test_edit_page.questions.elements_selected')}
              </Typography>
            </Stack>
            {sectionsArr && sectionsArr.length !== 0 && (
              <>
                <Stack direction="row" alignItems="center" spacing="8px">
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: 'grey.500',
                      // minWidth: '60px',
                      textAlign: 'center',
                    }}
                  >
                    {t('test_edit_page.questions.add_to')}
                  </Typography>
                  <TestSectionsDropdown
                    sectionOpts={itemSections as ITestSection[]}
                    selectedSectionId={questionTargetLocationId}
                    setSelectedSectionId={setQuestionTargetLocationId}
                    sx={{
                      minWidth: '320px',
                      maxHeight: '40px',
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ minWidth: '40px', backgroundColor: 'secondary.main' }}
                    onClick={() => handleMoveQuestions(questionTargetLocationId)}
                    disabled={!questionTargetLocationId}
                  >
                    {t('common.add')}
                  </Button>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: 'grey.500',
                    // minWidth: '70px',
                    textAlign: 'center',
                  }}
                >
                  {t('common.or')}
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="simple-line-icons:frame" />}
                  sx={{ backgroundColor: 'success.main' }}
                  onClick={handleNewSectionDialogOpen}
                >
                  {t('test_edit_page.questions.create_section')}
                </Button>
              </>
            )}
          </Stack>
        )}

        <FormProvider methods={methods} onSubmit={onSubmit}>
          {independentQuestions.length > 0 || (sectionsArr && sectionsArr.length > 0) ? (
            <>
              <Box mb={5}>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <Stack spacing="2rem">
                    <SortableContext
                      items={itemsWithQuestionNumbers as any}
                      strategy={verticalListSortingStrategy}
                      disabled={isAnalyst}
                    >
                      {itemsWithQuestionNumbers.map((item) => {
                        if (isQuestionOrSection(item) === 'question') {
                          return isEditing &&
                            (item._tempId === editQuestionId || item.id === editQuestionId) ? (
                            <QuestionForm
                              key={item._tempId || item.id}
                              currentQuestion={item as ITestQuestion}
                              handleCloseEditQuestion={handleCloseEditQuestion}
                              setDndItems={setDndItems}
                              isTestQuestion
                              shouldLimitEditing={isNewQuestion ? false : isEditing}
                              onSubmitTest={onSubmit}
                            />
                          ) : (
                            <TestQuestionsBox
                              key={item.id || item._tempId}
                              id={item.id || item._tempId!}
                              question={item as ITestQuestion}
                              questionNumber={(item as ITestQuestion).questionNumber}
                              handleEditQuestion={handleEditQuestion}
                              handleQuestionSelect={handleQuestionSelect}
                              handleRemoveQuestionFromSection={handleMoveQuestions}
                              selectedQuestions={selectedQuestions}
                              setDndItems={setDndItems}
                            />
                          );
                        }
                        if (isQuestionOrSection(item) === 'section') {
                          return (
                            <TestSectionBox
                              key={item.id || item._tempId}
                              id={item.id || item._tempId!}
                              section={item as SaveTestSectionsYupSchema}
                              handleEditQuestion={handleEditQuestion}
                              handleCloseEditQuestion={handleCloseEditQuestion}
                              handleQuestionSelect={handleQuestionSelect}
                              handleRemoveQuestionFromSection={handleMoveQuestions}
                              selectedQuestions={selectedQuestions}
                              editQuestionId={editQuestionId}
                              isEditing={isEditing}
                              setDndItems={setDndItems}
                            />
                          );
                        }
                        return null;
                      })}
                    </SortableContext>
                  </Stack>
                </DndContext>
              </Box>
              {!isAnalyst && renderAddQuestionToolbar}
              <Divider sx={{ borderStyle: 'dashed', my: '2rem' }} />

              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                  <RHFSwitch
                    name="isRandomized"
                    label={t('test_edit_page.questions.randomize_questions')}
                    sx={{ textWrap: 'nowrap' }}
                    disabled={isEditing || isAnalyst}
                  />
                  <RHFTextField
                    name="dimension"
                    size="small"
                    label={t('test_edit_page.questions.dimensions')}
                    value={formDimensions === null ? '' : formDimensions}
                    type="number"
                    onChange={(event) => {
                      const { value } = event.target;
                      methods.setValue('dimension', value === '' ? null : Number(value), {
                        shouldDirty: true,
                      });
                    }}
                    disabled={!isRandomizedWatch || isAnalyst}
                  />
                </Stack>
                <Stack display={isAnalyst ? 'none' : 'flex'} direction="row" spacing={2}>
                  <Button
                    onClick={handleCancel}
                    color="inherit"
                    size="large"
                    variant="outlined"
                    disabled={isEditing}
                  >
                    {t('common.cancel')}
                  </Button>
                  <LoadingButton
                    color="success"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isEditing || !isDirty}
                  >
                    {t('common.save')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <NoQuestionsAdded />
              {!isAnalyst && renderAddQuestionToolbar}
              {isDirty && (
                <Stack direction="row" justifyContent="end">
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={handleCancel}
                      color="inherit"
                      size="large"
                      variant="outlined"
                      disabled={isEditing}
                    >
                      {t('common.cancel')}
                    </Button>
                    <LoadingButton
                      color="success"
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={isEditing || !isDirty}
                    >
                      {t('common.save')}
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </FormProvider>
      </Stack>
      <Dialog
        open={newSectionDialog.value}
        onClose={handleDialogClose}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '720px',
          },
        }}
      >
        <FormProvider methods={methods}>
          <DialogTitle sx={{ color: 'info.dark', fontSize: '18px', fontWeight: '900' }}>
            {t('test_edit_page.questions.new_section')}
          </DialogTitle>
          <DialogContent sx={{ paddingTop: '4px !important' }}>
            <TextField
              autoFocus
              label={t('common.name')}
              type="text"
              fullWidth
              variant="outlined"
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
            />
          </DialogContent>
          <DialogContent sx={{ paddingTop: '1rem !important' }}>
            <RHFEditor
              name="instruction"
              simple
              value={newSectionInstruction}
              placeholder={t('common.instructions')}
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
              onChange={(value) => setNewSectionInstruction(value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="inherit" variant="outlined">
              {t('common.cancel')}
            </Button>
            <Button color="success" variant="contained" onClick={handleCreateSection}>
              {t('common.save')}
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}

export default TestEditQuestionsView;
