import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { TABS } from 'src/constants/questions';
import { useGetQuestion } from 'src/api/question';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import QuestionForm from 'src/components/question/question-form';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import TestTable from 'src/sections/staff/tests/tests/test-table';

import { IFetchBaseParams } from 'src/types/misc';

export default function QuestionEditView({ id }: IFetchBaseParams) {
  const [searchParam] = useSearchParams();
  const { t } = useTranslate();
  const isNew = searchParam.get('new') === 'true';

  const settings = useSettingsContext();

  const { data: question, isPending } = useGetQuestion({ id });

  if (!isPending && isNew) {
    delete question?.id;
  }

  const [currentTab, setCurrentTab] = useState<string>(TABS[0].name);

  if (isPending)
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <LoadingScreen />
      </Container>
    );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('common.questions')}
        links={[
          { name: t('common.dashboard'), href: '#' },
          { name: t('common.questions'), href: paths.staff.test.questions.root },
          {
            name: isNew
              ? t('questions_edit_page.duplicate_question')
              : t('questions_edit_page.edit_question'),
            href: '#',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Tabs
        value={currentTab}
        onChange={(_, newVal) => setCurrentTab(newVal)}
        sx={{
          mb: 5,
        }}
      >
        {TABS.map((type) => (
          <Tab
            key={type.name}
            sx={{ fontWeight: 'fontWeightBold' }}
            value={type.name}
            iconPosition="start"
            icon={
              <Iconify
                icon={type.iconifyIcon}
                width={24}
                color={currentTab === type.name ? 'info.dark' : 'grey.600'}
              />
            }
            label={type.name}
          />
        ))}
      </Tabs>

      {/* General View */}
      {currentTab === TABS[0].name && !isPending && (
        <QuestionForm currentQuestion={question} isUpdating />
      )}

      {/* Tests View */}
      {currentTab === TABS[1].name && !isPending && <TestTable questionId={id} />}
    </Container>
  );
}
