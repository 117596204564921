import { Navigate, useRoutes } from 'react-router-dom';

// import MainLayout from 'src/layouts/main';

// import { paths } from 'src/routes/paths';

// import { isLoggedIn } from 'src/utils/check-user-is-admin';

// import { lazy } from 'react';

// import { AuthGuard } from 'src/auth/guard';

// import GuestGuard from 'src/auth/guard/guest-guard';
// import CompleteLoginView from 'src/pages/auth/firebase/complete-login';

// import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { errorRoutes } from './error';
import { staffRoutes } from './staff';
// import { studentRoutes } from './student';
// import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  // let indexPath = paths.login;
  // if (isLoggedIn()) {
  // }
  return useRoutes([
    // DEPRECATED: MagicLink Verify Page
    /* {
      path: paths.completeLogin,
      element: (
        <GuestGuard redirect={false}>
          <CompleteLoginView />
        </GuestGuard>
      ),
    }, */

    // Main routes
    ...mainRoutes,

    // Staff routes
    ...staffRoutes,

    // Dashboard routes
    // ...dashboardRoutes,

    // Student routes
    // ...studentRoutes,

    // Error routes
    ...errorRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
