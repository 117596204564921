import axiosInstance, { endpoints } from 'src/utils/axios';

import {
  ICreateUser,
  ICheckStudentExists,
  IFirebaseLoginPayload,
  IVerifyEmailOTPPayload,
} from 'src/types';

export async function createUser(data: ICreateUser) {
  const URL = `${endpoints.auth.register.student}`;
  const resData = await axiosInstance.post(URL, data);
  return resData.data;
}

export async function checkAccountExists(data: ICheckStudentExists) {
  const URL = `${endpoints.auth.checkAccountExist}`;
  const resData = await axiosInstance.post(URL, data);
  return resData.data.data;
}

export async function sendSignInLinkToEmail(data: IFirebaseLoginPayload) {
  const resData = await axiosInstance.post(endpoints.auth.link.login.root, data);
  return resData.data.data;
}

export async function verifyEmailOTP(data: IVerifyEmailOTPPayload) {
  const resData = await axiosInstance.post(endpoints.auth.link.login.verify, data);
  return resData.data.data;
}
