import { Grid, styled, Typography } from '@mui/material';

import { ENVIRONMENT } from 'src/config-global';
import { ENVIRONMENTS } from 'src/constants/misc';

const BannerBg = styled(Grid)({
  backgroundColor: ENVIRONMENT === ENVIRONMENTS.stage ? '#FFF7AF' : '#EBC9C9',
  position: 'fixed',
  top: 0,
  zIndex: '1102',
  height: '1.875rem',
  maxHeight: '1.875rem',
});

const BannerText = styled(Typography)({
  fontWeight: 500,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  color: '#212529',
  textAlign: 'center',
  textTransform: 'uppercase',
});

export const BANNER_HEIGHT_OFFSET = '0.1rem';
export const BANNER_HEIGHT_OFFSET_DASHBOARD = '0.1rem';
export const BANNER_HEIGHT_OFFSET_SIDEBAR = '1.8rem';
export const BANNER_HEIGHT_OFFSET_TIMER = '1.9rem';

const Banner = () => {
  if (ENVIRONMENT !== ENVIRONMENTS.prod)
    return (
      <BannerBg container alignItems="center">
        <Grid item xs={12}>
          <BannerText>{`${ENVIRONMENT}`}</BannerText>
        </Grid>
      </BannerBg>
    );

  return null;
};

export default Banner;
